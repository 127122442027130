import Vue from 'vue'
import 'vant/lib/index.css';
import "./assets/css/reset.scss";
import App from './App.vue'
import router from './routes';
import VueI18n from 'vue-i18n'
import messages from './i18n/index'
import 'lib-flexible'
import Vant from 'vant';

import api from '@/api/api';

Vue.use(Vant);
Vue.use(VueI18n)
Vue.config.productionTip = false
Vue.prototype.$api = api;
let location = window.location;
let origin = location.origin;
let I18nLocale = "zh-CN";
if(origin.indexOf("bostonfang")>-1){
  I18nLocale = "zh-CN";
}

if(origin.indexOf("blueoceanboston")>-1){
  I18nLocale = "en-US";
}

const i18n = new VueI18n({
  locale: I18nLocale,
  messages: messages,
  fallbackLocale: I18nLocale,
});

document.title = i18n.t("company");
let vm = new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
api.initVue(vm);
window.vm = vm;
