import Vue from 'vue'
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home')
    },
    {
        path: "/contact",
        name:'contact',
        component: () => import(/* webpackChunkName: "home" */ '../views/Contact')
    },
    {
        path: "/othercontact",
        component: () => import(/* webpackChunkName: "home" */ '../views/OtherContact')
    },
    {
        path: "/login",
        name: 'login',
        component: () => import(/* webpackChunkName: "home" */ '../views/Login')
    },
    {
        path: "/my",
        name: 'my',
        component: () => import(/* webpackChunkName: "home" */ '../views/My')
    },
    {
        path: "/list",
        name:'list',
        component: () => import(/* webpackChunkName: "home" */ '../views/List')
    },
    {
        path: "/saledetail",
        name:"saledetail",
        component: () => import(/* webpackChunkName: "home" */ '../views/SaleDetail')
    },
    {
        path: "/rentdetail",
        name:"rentdetail",
        component: () => import(/* webpackChunkName: "home" */ '../views/RentDetail')
    }
];

const router = new VueRouter({
    routes
});

export default router;
