<template>
  <div id="app">
    <div class="body__app">
<!--      <div class="globle__info">-->
<!--        <div class="qrcode__box">-->
<!--          <div class="qrcode__box&#45;&#45;item">-->
<!--            <div class="qrcode__thumb">-->
<!--              <img src="./assets/images/company__code.png" alt="">-->
<!--            </div>-->
<!--            <div class="qrcode__desc">{{$t('wechatconsulting')}}</div>-->
<!--          </div>-->
<!--          <div class="qrcode__box&#45;&#45;item">-->
<!--            <div class="qrcode__thumb">-->
<!--              <img src="./assets/images/boss__code.png" alt="">-->
<!--            </div>-->
<!--            <div class="qrcode__desc">{{$t('weChatofficialaccount')}}</div>-->
<!--          </div>-->
<!--          <div class="qrcode__box&#45;&#45;item">-->
<!--            <div class="qrcode__thumb">-->
<!--              <img src="./assets/images/mini__code.png" alt="">-->
<!--            </div>-->
<!--            <div class="qrcode__desc">{{$t('followMiniPrograms')}}</div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="wechat__info">-->
<!--          <p>{{$t('WeChatofficial')}}：BostonFang2009</p>-->
<!--          <p>{{$t('Realmanager')}}：RoseHall</p>-->
<!--        </div>-->
<!--      </div>-->
      <router-view></router-view>
    </div>
    <van-tabbar v-model="tabbar.active">
      <van-tabbar-item to="/" name="home" icon="wap-home">{{$t('nav.HOME')}}</van-tabbar-item>
      <van-tabbar-item to="/contact" name="contact" icon="comment">{{$t('about.contact.comment')}}</van-tabbar-item>
      <van-tabbar-item to="/my" name="my" icon="manager">{{$t('my')}}</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>

export default {
  name: 'App',
  data(){
    return {
      tabbar:{
        active:""
      }
    }
  },
  watch:{
    '$route'(n,o){
      let name = n.name;
      this.tabbar.active = name;
    }
  },
  async created() {
    let JSESSIONIDRes = await this.$api.requestGetJSESSIONID();
    localStorage.setItem("JSESSIONID",JSESSIONIDRes.data.JSESSIONID);
  }
}
</script>

<style lang="scss">
  .block{
    background: #fff;
    border-radius: 10px;
    padding: 28px;
  }

  .text__button{
    background: transparent;
    border: none;
    padding: 0;
    height: auto;
    line-height: inherit;
    font-size: 28px;
  }
</style>
