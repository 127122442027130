import axios from 'axios';
import {Toast} from 'vant';
axios.defaults.baseURL ='/blueocean';
axios.defaults.timeout = 3 * 60 * 1000;
axios.defaults.withCredentials = true;
let notify = false;
//http request 拦截器
axios.interceptors.request.use(
  config => {
    let vm = axios.prototype.vm
    let loading = config.loading;
    notify = config.notify;
    let token = localStorage.getItem("token");
    let JSESSIONID = localStorage.getItem("JSESSIONID");
    return config;
  },
  error => {
    return Promise.reject(err);
  }
);

//http response 拦截器
axios.interceptors.response.use(
  response => {
    let vm = axios.prototype.vm
    let res = response.data;
    let code = parseInt(res.code);
    let msg = res.message;
    if(code === 1250){
        localStorage.removeItem("userInfo");
        localStorage.removeItem("JSESSIONID");
        localStorage.removeItem("code");
        localStorage.removeItem("mobile");
        vm.$router.push({
            path:'/login'
        })
    }
    if(code>1000){
        Toast.fail(msg)
        return Promise.resolve(response)
    }
    return response;
  },
  (error) => {
    let vm = axios.prototype.vm
      Toast.clear();
    return Promise.reject(error)
  }
);

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

function get(url,params={},config){
  let c = Object.assign({},{params},config)
  return new Promise((resolve,reject) => {
    axios.get(url,c)
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err)
      })
  })
}
/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

function post(url,data = {},config){
  return new Promise((resolve,reject) => {
    axios.post(url,data,config)
      .then(response => {
        resolve(response.data);
      },err => {
        reject(err)
      })
  })
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

function patch(url,data = {}){
  return new Promise((resolve,reject) => {
    axios.patch(url,data)
      .then(response => {
        resolve(response.data);
      },err => {
        reject(err)
      })
  })
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

function put(url,data = {}){
  return new Promise((resolve,reject) => {
    axios.put(url,data)
      .then(response => {
        resolve(response.data);
      },err => {
        reject(err)
      })
  })
}
/**
 * 封装delete请求
 * @param  url
 * @param  params
 * @returns {Promise}
 */
function Delete(url,params={}){
  return new Promise((resolve,reject) => {
    axios.delete(url,{
      params:params
    })
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err)
      })
  })
}

export default {
  axios,
  get,
  post,
  put,
  Delete,
  initVue(vm) {
    axios.prototype.vm = vm;
  }
}
