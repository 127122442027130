import Http from "./http.js";

export default {
  initVue(vm){
    Http.initVue(vm);
  },
  //二手房接口
  getSaleList(params){
    return Http.get("/sale/listings/get.action",params)
  },
  //搜索接口
  saleListingsSearch(params,config){
    return Http.post("/sale/listings/search.action",params,Object.assign({},{
      loading:false
    },config))
  },
  //获取学校地理信息接口
  saleSchoolsDistrictsSearch(params){
    return Http.get("/sale/schools/districts/search.action",params)
  },
  //学校接口
  saleSchoolAttendanceAreasSearch(params){
    return Http.get("/sale/schools/attendance-areas/search.action",params)
  },
  //学校分类接口
  saleSchoolSearch(params){
    return Http.get("/sale/schools/search.action",params)
  },
  //学校详情
  saleSchoolsGet(params){
    return Http.get("/sale/schools/get.action",params)
  },
  //租房接口
  rentRentalsSearch(params){
    return Http.get("/rent/rentals/search.action",params)
  },
  //租房详情接口
  rentRentalsSearchById(params){
    return Http.get("/rent/rentals/search.action",params)
  },
  //发送咨询接口
  requestSendTouch(params){
    return Http.post("/user/sendTouch.action",params)
  },
  //发送验证码
  requestSendVerificationCode(params){
    return Http.get("/user/sendVerificationCode.action",params)
  },
  //登录
  requestMobileLogin(params){
    return Http.get("/user/mobileLogin.action",params)
  },
  //收藏二手房
  requestCollectionSaveSaleListings(params){
    return Http.get("/v1/collection/saveSaleListings.action",params)
  },
  //取消二手房
  requestCollectionCancelSaleListings(params){
    return Http.get("/v1/collection/cancelSaleListings.action",params)
  },
  //收藏租房
  requestCollectionSaveRentListings(params){
    return Http.get("/v1/collection/saveRentListings.action",params,{
      loading:true,
      notify:true
    })
  },
  //取消收藏租房
  requestCollectionCancelRentListings(params){
    return Http.get("/v1/collection/cancelRentListings.action",params,{
      loading:true,
      notify:true
    })
  },
  //获取JSESSIONID
  requestGetJSESSIONID(){
    return Http.get("/user/getJSESSIONID.action");
  },
  //请求收藏二手房列表
  requestGetFavouriteSaleList(params) {
    return Http.get("/v1/collection/getSaleList.action",params)
  },
  //获取用户信息
  getUserInfo(){
    return Http.get("/user/getUserInfo.action")
  },
  //请求收藏租房列表
  requestGetFavouriteRentList(params) {
    return Http.get("/v1/collection/getRentList.action",params)
  },
  //退出
  requestLoginOut(params) {
    return Http.get("/user/loginOut.action",params)
  },
  //
  requestSaleSchoolSearch(params,config) {
    return Http.post("/sale/schools/search.action",params,config)
  },
  requestAreasSearch(params,config){
    return Http.post("/sale/areas/search.action",params,config);
  },
  requestSaleAreasSearch(params,config) {
    return Http.post("/sale/areas/search.action",params,config);
  },
}
